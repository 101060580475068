const registration_params = {
  response_type: 'code',
  client_id: process.env.STRIPE_CLIENT_ID,
  redirect_uri: process.env.WEBSITE_DOMAIN + '/api/auth/login',
  scope: 'read_write',
  'stripe_user[physical_product]': false
}

const login_params = {
  ...registration_params,
  stripe_landing: 'login'
}

function stripeUrl(params) {
  return "https://connect.stripe.com/oauth/authorize?" + new URLSearchParams(params)
}

export const login_url = stripeUrl(login_params)
export const registration_url = stripeUrl(registration_params)
