<script>
import { login_url } from './stripe-urls'

export let page
</script>

<h1>Welcome to Meriluna</h1>

<section>
  <div class="body">
    <h2>Login</h2>
    <p>Welcome back!</p>
  </div>
  <p>
    <a class="button" href="{ login_url }">Login using Stripe</a>
  </p>
</section>

<section>
  <div class="body">
    <h2>New user?</h2>
    <p>Don't worry, we've got you covered.</p>
  </div>
  <p>
    <button on:click={ () => page = "register" }>Register</button>
  </p>
</section>

<style>
h2, p {
  margin: 0;
}
section {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 2em 0;
}
</style>
