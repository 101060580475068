<script>
import { login_url } from './stripe-urls'

import Login from './login.svelte'
import Register from './register.svelte'

let page = "login"
</script>

<svelte:head>
  <title>Meriluna</title>
</svelte:head>

{#if page === "login"}
  <Login bind:page />
{:else}
  <Register bind:page />
{/if}
