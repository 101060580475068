<script>
import { registration_url } from './stripe-urls'

export let page = "register"
</script>

<h1>Registration</h1>
<p>You will need to link an existing account or create a new account with Stripe.</p>
<p>Meriluna uses <a href="https://stripe.com/">Stripe</a> to handle all financial transactions.  Meriluna will never hold onto funds and will immediately direct all payments to your Stripe account.</p>
<p>After connecting your Stripe account, you will be redirected back to Meriluna to finish your registration.</p>
<p>
  <a class="button" href={ registration_url }>Connect with Stripe</a>
</p>
<p>
  <a href="/" on:click|preventDefault={ () => page = "login" }>Go Back</a>
</p>
